<template>
  <section id="intro" class="section section--intro">
    <div class="section-inner">
      <b-container class="text-center position-relative">
        <h1>
          <a href="/">
            <span class="sr-only">4in1clean</span>
            <logo class="logo" />
          </a>
        </h1>
        <div class="claim img-shadow">
          Nastříkat,<br>
          nechat vstřebat,<br>
          setřít<br>
          ...hotovo!
          <stars class="stars" />
        </div>
      </b-container>
    </div>
  </section>
</template>

<script>
import Logo from '~/assets/svg/logo.svg?inline'
import Stars from '~/assets/svg/stars.svg?inline'

export default {
  name: 'MainIntroSection',
  components: {
    Logo,
    Stars
  }
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/sections/main-intro-section";
</style>
